import { useParams } from "react-router-dom";
import { useGetTravelDetailsQuery } from "../../../../store/queries/orders";

const useUserDetails = () => {
  const { ID } = useParams();

  const { data: mainData, isFetching } = useGetTravelDetailsQuery({
    order_id: localStorage.getItem("product_order_id") ?? ID,
  });

  const travelDetailLabel = {
    purpose: "Purpose of Travel",
    doTravel: "Date of Travel",
    to_country: "Travel to Country",
    fund_source_name: "Source of Fund",
    own_fund: "Own Amount",
    loan_fund: "Loan Amount",
  };

  const travelDetailValue = {
    purpose: `${mainData?.data?.purpose?.name ?? "Not specified"}`,
    doTravel: `${
      mainData?.data?.date !== "" ? mainData?.data?.date : "Not specified"
    }`,
    to_country: `${mainData?.data?.country?.name ?? "Not specified"}`,
    fund_source_name: `${mainData?.data?.fund_source_name ?? "Not specified"}`,
    own_fund: `${mainData?.data?.own_fund ?? "No amount"}`,
    loan_fund: `${mainData?.data?.loan_fund ?? "No amount"}`,
  };

  const travelDetails = Object.keys(travelDetailValue)
  .filter((key) => travelDetailValue[key] !== "No amount")
  .map((key) => {
    return {
      label: travelDetailLabel[key],
      value: typeof travelDetailValue[key] === "string"
              ? travelDetailValue?.[key]
              : typeof travelDetailValue[key] === "number"
              ? travelDetailValue?.[key]
              : travelDetailValue?.[key]?.name,
    };
  });
  return {
    travelDetails,
    isFetching,
  };
};

export default useUserDetails;
