import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
// import { updateConfig } from "../../../../Store/Slices/Expertise/expertiseSlice";
// import { createExpertise, updateExpertise } from "../api";
import { createTravelDetails, updateTravelDetails } from "./api";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  getViewData,
  updateConfig,
} from "../../../../../store/slices/OrderCreate/orderCreateSlice";
import { useEffect } from "react";
import Assets from "../../../../../assets/Assets";

const useTravelDetails = ({ refetch }) => {
  const dispatch = useDispatch();
  const { isEdit, itemData, itemId, orderBasicData, imagePreview, orderId } =
    useSelector((state) => state.orderCreate);

  const { basicData } = useSelector((state) => state.global);
  const orderCreateState = useSelector((state) => state.orderCreate);
  const minDate = new Date(); // Today
  const maxDate = new Date();
  maxDate.setDate(minDate.getDate() + 60);

  const validationSchema = Yup.object({
    travel_purpose: Yup.string().required("*Please select the travel purpose"),
    travel_date: Yup.string().required("*Please select the travel date"),
    country: Yup.string().required("*Please select the country"),
    fund_source: Yup.string().test(
      "is-required",
      "*Source of fund is required",
      function (value) {
        const { travel_purpose } = this.parent;
        const isEducationOverseas =
          orderBasicData?.travel_purpose_list?.find(
            (item) => item?._id === travel_purpose
          )?.name === "Education Overseas";
        // If the condition is not met, validation should pass (not required)
        if (!isEducationOverseas) {
          return true; // Field is not required
        }

        // If it is required, check if the field has a value
        return !!value; // Ensures `value` is not empty
      }
    ),

    loan_fund: Yup.string().test(
      "is-required",
      "*Loan amount is required",
      function (value) {
        const { fund_source } = this.parent;
        const isEducationOverseas = (fund_source === "2")
        // If the condition is not met, validation should pass (not required)
        if (!isEducationOverseas) {
          return true; // Field is not required
        }

        // If it is required, check if the field has a value
        return !!value; // Ensures `value` is not empty
      }
    ),

    // own_fund: Yup.string().test(
    //   "is-required",
    //   "*Own amount is required",
    //   function (value) {
    //     const { fund_source } = this.parent;
    //     const isEducationOverseas = (fund_source === "2")
    //     // If the condition is not met, validation should pass (not required)
    //     if (!isEducationOverseas) {
    //       return true; // Field is not required
    //     }

    //     // If it is required, check if the field has a value
    //     return !!value; // Ensures `value` is not empty
    //   }
    // ),

    loan_document: Yup.mixed()
      .test("is-required", "*Loan Document is required", function (value) {
        const { fund_source, travel_purpose } = this.parent;
        const isEducationOverseas =
          orderBasicData?.travel_purpose_list?.find(
            (item) => item?._id === travel_purpose
          )?.name === "Education Overseas";
        if (
          (fund_source === "1" || fund_source === "2") &&
          isEducationOverseas &&
          !value &&
          !itemData?.order_documents?.documents?.find(
            (item) => item?.id_type === 7
          )
        ) {
          return false;
        }
        return true;
      })
      .test("fileType", "Invalid file format", (value) => {
        if (value && !itemData?.order_documents?.documents?.find(
          (item) => item?.id_type === 7
        )) {
          const supportedFormats = ["image/jpeg", "image/png", "image/svg+xml","application/pdf"];
          return supportedFormats.includes(value.type);
        }
        return true;
      })
      .test("fileSize", "Please upload a file less than 2MB", (value) => {
        if (value && !itemData?.order_documents?.documents?.find(
          (item) => item?.id_type === 7
        )) {
          return value.size <= 2097152;
        }
        return true;
      }),
  });
  const isURLFormat = (s) => {
    const urlPattern = /^https?:\/\/\S+/;
    return urlPattern.test(s);
  };
  const calculateTotalGST = (gstData) => {
    if (!gstData || typeof gstData !== "object") return 0;
  
    // Sum all values in the gstData object
    return Object.values(gstData).reduce((acc, value) => acc + parseFloat(value || 0), 0);
  };

  const loanAmountChange = (e) => {
    let value = e.target.value;
  
    // Allow only valid numbers
    if (value === "" || isNaN(value)) {
      formik.setFieldValue("loan_fund", ""); // Reset to empty if invalid
      return;
    }
  
    const parsedValue = parseFloat(value);
    const forex_amount = itemData?.forex_amount ?? 0;
    const totalGST = calculateTotalGST(itemData?.gst);
    const finalAmount = Math.ceil(forex_amount + totalGST);

    let total = finalAmount;
  
    // Prevent value from being equal to total
    if (parsedValue === total || parsedValue >= total) {
      toast.error("Loan amount cannot be equal to or greater than the total amount.");
      return; // Ignore the value if it equals total, but keep the current value
    }
    if(parsedValue === 0){
      toast.error("The amount should be greater than zero");
      return; // Ignore the value if it equals total, but keep the current value
    }
  
    // Restrict to maximum allowed total
    if (parsedValue > total) {
      parsedValue = total;
    }
  
    // Only set the value if it's valid and not equal to the total
    formik.setFieldValue("loan_fund", value); // Keep original value in the input
  };
  

  useEffect(() => {

    if (itemData?.order_documents?.documents?.length) {
      const filterData = itemData?.order_documents?.documents?.find(
        (item) => item?.id_type === 7
      );
  
      if (filterData?.name) {
        const fileUrl = filterData.name;
        const isPDF = fileUrl.toLowerCase().endsWith(".pdf");
  
        dispatch(
          updateConfig((state) => {
            state.imagePreview = isPDF ? Assets.PDF_IMAGE : fileUrl;
          })
        );
      }
    }
    // eslint-disable-next-line
  }, [itemData?.order_documents]);

  const formik = useFormik({
    initialValues: {
      travel_purpose: itemData?.travel_details?.purpose?.id ?? "",
      travel_date: itemData?.travel_details?.date ?? "",
      country: itemData?.travel_details?.country?.id ?? "",
      fund_source:itemData?.travel_details?.fund_source ?? "",
      loan_fund: itemData?.loan_fund ?? "",
      own_fund: itemData?.own_fund ?? "",
      loan_document: itemData?.order_documents?.documents?.find(
        (item) => item?.id_type === 7
      )?.name ?? "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      let data = {
        travel_purpose: values.travel_purpose,
        travel_date: values?.travel_date,
        country: values?.country,
        travel_declaration: 1,
        previous_amount: 0,
        order_id: orderId,
      };
      let isEducation = formik?.values?.travel_purpose
      && orderBasicData?.travel_purpose_list?.find(
          (item) => item?._id === formik?.values?.travel_purpose
        )?.name === "Education Overseas"
      if (
        (values?.loan_document === "" &&
          orderCreateState?.EditCreate.travelDetails) ||
        (values?.fund_source != 1 || values?.fund_source != 2)
      ) {
        delete data.loan_document;
      }
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });

      if (values?.loan_fund && values?.fund_source === 2 && isEducation) {
        formData.append("loan_fund", values?.loan_fund);
      }
      if (values?.own_fund && values?.fund_source === 2 && isEducation) {
        formData.append("own_fund", values?.own_fund);
      }
      if (values?.fund_source === "" || values?.fund_source === undefined || !isEducation) {
        formData.append("fund_source", 0);
      } else {
        formData.append("fund_source", values?.fund_source);
      }
      if (!isURLFormat(values?.loan_document) && values?.fund_source === 2 && isEducation) {
        formData.append("loan_document", values?.loan_document);
      }
      if (!isURLFormat(values?.loan_document) && values?.fund_source === 1 && isEducation) {
        formData.append("loan_document", values?.loan_document);
      }

      formData.append("_method", "PUT");
      createTravelDetails(formData).then((response) => {
        if (response?.status === 200) {
          toast.success("Travel details have been added.");
          dispatch(
            updateConfig((state) => {
              state.formActiveTab = "Documents";
              state.imagePreview = "";
              state.EditCreate.productDetails =
                orderCreateState?.EditCreate.productDetails;
              state.EditCreate.travelDetails = true;
              state.EditCreate.deliveryDetails =
                orderCreateState?.EditCreate.deliveryDetails;
              state.EditCreate.documentDetails =
                orderCreateState?.EditCreate.documentDetails;
            })
          );
          setSubmitting(false);
          handleView();
        } else if (response?.status === 422) {
          let errors = response?.errors;
          let errorFields = Object.keys(errors);
          errorFields?.forEach((field) => {
            formik.setFieldError(field, errors[field]);
          });
          setSubmitting(false);
        } else if (response?.success === false) {
          let errors = response?.data;
          let errorFields = Object.keys(errors);
          errorFields?.forEach((field) => {
            formik.setFieldError(field, errors[field]);
          });
          setSubmitting(false);
        } else {
          toast.error("Something went wrong");
          setSubmitting(false);
        }
      });
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleImageChange = (event) => {
    const selectedImage = event?.target?.files[0];
  
    if (selectedImage) {
      formik.setFieldValue("loan_document", selectedImage);
      const file = selectedImage;
      const reader = new FileReader();
      const fileType = file.type;
  
      if (fileType === "application/pdf") {
        // Show PDF icon if it's a PDF
        dispatch(
          updateConfig((state) => {
            state.imagePreview = Assets.PDF_IMAGE; // Change this path to your PDF icon
          })
        );
      } else if (fileType.startsWith("image/")) {
        // If it's an image, preview it
        reader.onloadend = () => {
          dispatch(
            updateConfig((state) => {
              state.imagePreview = reader.result;
            })
          );
        };
        reader.readAsDataURL(file);
      }
    }
  };
  

  const handleImageRemove = () => {
    dispatch(
      updateConfig((state) => {
        state.imagePreview = "";
      })
    );
    formik.setFieldValue("loan_document", "");
    formik?.setFieldError("loan_document", "Upload image");
    formik?.setFieldTouched("loan_document", true);
  };
  (function () {
    let fields = [
      "travel_purpose",
      "travel_date",
      "country",
      ...(orderBasicData?.travel_purpose_list?.find(
        (item) => item?._id === formik?.values?.travel_purpose
      )?.name === "Education Overseas"
        ? ["fund_source"]
        : []),
      ...((formik?.values?.fund_source === 1 || formik?.values?.fund_source === 2) ? ["loan_document"] : []),
    ];
    // eslint-disable-next-line array-callback-return
    var completedFields = fields?.filter((field) => {
      if (Array.isArray(formik?.values[field])) {
        return formik?.values[field]?.length > 0;
      } else {
        return !!formik?.values[field];
      }
    });
    dispatch(
      updateConfig((state) => {
        state.travelDetails_percentage =
          (completedFields.length / fields.length) * 100;
      })
    );
    // return
  })();
  const handleNext = () => {
    formik.handleSubmit();
  };
  const handleView = () => {
    dispatch(getViewData({ id: orderId }));
  };

  useEffect(() => {
    if (formik.values.fund_source === 2) {
      const loanAmount = formik.values.loan_fund;
      if (loanAmount !== undefined && loanAmount !== null && loanAmount !== "") {
        const forex_amount = itemData?.forex_amount ?? 0;
        const totalGST = calculateTotalGST(itemData?.gst);
        const total = Math.ceil(forex_amount + totalGST); 
        const ownFund = total - loanAmount;
        formik.setFieldValue("own_fund", ownFund >= 0 ? ownFund : "");
      } else {
        formik.setFieldValue("own_fund", "");
      }
    }
  }, [formik.values.loan_fund, formik.values.fund_source]);

  return {
    formik,
    isEdit,
    orderBasicData,
    imagePreview,
    getFieldError,
    handleImageChange,
    handleImageRemove,
    handleNext,
    isURLFormat,
    basicData,
    itemData,
    loanAmountChange,
    calculateTotalGST,
  };
};

export default useTravelDetails;
