import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useTravelDetails from "./useTravelDetails";
import FileUpload from "../../../../Global/FileUpload";

const TravelDetails = ({
  // formik,
  refetch,
  providersState,
  handleView,
  status,
  setStatus,
}) => {
  const {
    formik,
    orderBasicData,
    imagePreview,
    getFieldError,
    handleImageChange,
    handleImageRemove,
    handleNext,
    itemData,
    loanAmountChange,
    calculateTotalGST,
  } = useTravelDetails({
    refetch,
    providersState,
    // getFieldError,
    setStatus,
    status,
  });
  return (
    <div className="col">
      <div className="pro-mb-4">
        <label
          htmlFor="travel_purpose"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Select Purpose of Travel
        </label>
        <Select
          id="travel_purpose"
          placeholder={"Select Purpose of Travel"}
          className={`pro-input lg  ${
            getFieldError("travel_purpose") && " error"
          }`}
          classNamePrefix="pro-input"
          name="travel_purpose"
          options={orderBasicData?.travel_purpose_list ?? []}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={orderBasicData?.travel_purpose_list?.find(
            (value) => value?._id === formik?.values?.travel_purpose
          )??""}
          onBlur={formik.handleBlur("travel_purpose")}
          onChange={(value) => {
            formik.setFieldValue("travel_purpose", value?._id ?? "");
            formik.setFieldValue("fund_source", itemData?.travel_details?.fund_source ?? "");
            formik.setFieldValue("loan_fund", itemData?.loan_fund ?? "");
            formik.setFieldValue("own_fund", itemData?.own_fund ?? ""); 
            formik.setFieldValue("loan_document", itemData?.travel_details?.loan_document ??  ""); 
          }}
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("travel_purpose") && (
          <span className="error-text">{getFieldError("travel_purpose")}</span>
        )}
      </div>
      <div className={`input-wrap ${!formik?.values?.travel_date ? "input-placeholder" : ""}`}>
        <Input
          label={"Select Date of Travel"}
          type="date"
          id="travel_date"
          name="travel_date"
          className={`pro-input lg ${getFieldError("travel_date") && " error"}`}
          {...formik.getFieldProps("travel_date")}
          value={formik?.values?.travel_date}
          error={getFieldError("travel_date")}
          errorMessage={getFieldError("travel_date")}
          min={new Date().toISOString().split("T")[0]} // Today's date
          max={
            new Date(new Date().setDate(new Date().getDate() + 60))
              .toISOString()
              .split("T")[0]
          } // Today's date + 60 days
        />
      </div>
      <div className="pro-mb-4">
        <label htmlFor="country" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Select Tarvelling to Country
        </label>
        <Select
          id="country"
          placeholder={"Country"}
          className={`pro-input lg  ${getFieldError("country") && " error"}`}
          classNamePrefix="pro-input"
          name="country"
          options={orderBasicData?.countries ?? []}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={orderBasicData?.countries?.find(
            (value) => value?._id === formik?.values?.country
          )}
          onBlur={formik.handleBlur("country")}
          onChange={(value) =>
            formik.setFieldValue("country", value?._id ?? "")
          }
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("country") && (
          <span className="error-text">{getFieldError("country")}</span>
        )}
      </div>
      {(formik?.values?.travel_purpose
        && orderBasicData?.travel_purpose_list?.find(
            (item) => item?._id === formik?.values?.travel_purpose
          )?.name === "Education Overseas"
      ) && (
        
        <div className="pro-mb-4">
        <label
          htmlFor="fund_source"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Source of Fund
        </label>
        <Select
          id="fund_source"
          placeholder={"Select source of Fund"}
          className={`pro-input lg  ${
            getFieldError("fund_source") && " error"
          }`}
          classNamePrefix="pro-input"
          name="fund_source"
          options={orderBasicData?.fund_source ?? []}
          getOptionValue={(option) => option?.value}
          getOptionLabel={(option) => option?.name}
          value={orderBasicData?.fund_source?.find(
            (value) => value?.value === formik?.values?.fund_source
          )??""}
          onBlur={formik.handleBlur("fund_source")}
          onChange={(value) => {
            formik.setFieldValue("fund_source", value?.value ?? "");
            formik.setFieldValue("loan_fund", itemData?.loan_fund ?? "");
            formik.setFieldValue("own_fund", itemData?.own_fund ?? ""); 
            formik.setFieldValue("loan_document", itemData?.travel_details?.loan_document ??  ""); 
          }}
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("fund_source") && (
          <span className="error-text">{getFieldError("fund_source")}</span>
        )}
      </div>
      )}
       {formik?.values?.fund_source === 2 &&
        (formik?.values?.travel_purpose
          ? orderBasicData?.travel_purpose_list?.find(
              (item) => item?._id === formik?.values?.travel_purpose
            )?.name === "Education Overseas"
          : true) && (
          <div className="pro-mb-4">
             <Input
                   label={"Total Amount"}
                   type="number"
                   id="total_amount"
                   name="total_amount"
                   className={`pro-input lg`}
                   value={Math.ceil((itemData?.forex_amount ?? 0) + calculateTotalGST(itemData?.gst))}
                   disabled
                 />
             <Input
                   label={"Loan Amount*"}
                   type="text"
                   id="loan_fund"
                   name="loan_fund"
                   className={`pro-input lg ${
                     formik.errors.loan_fund && formik.touched.loan_fund && "error"
                   }`}
                   {...formik.getFieldProps("loan_fund")}
                  onChange={(e) => loanAmountChange(e)}
                  value={formik.values?.loan_fund}
                   error={formik.errors.loan_fund && formik.touched.loan_fund}
                   errorMessage={formik.errors.loan_fund}
                 />
                  <Input
                   label={"Own Amount*"}
                   type="number"
                   id="own_fund"
                   name="own_fund"
                   className={`pro-input lg ${
                     formik.errors.own_fund && formik.touched.own_fund && "error"
                   }`}
                   {...formik.getFieldProps("own_fund")}
                   onChange={formik.handleChange}
                   value={formik.values?.own_fund}
                   error={formik.errors.own_fund && formik.touched.own_fund}
                   errorMessage={formik.errors.own_fund}
                   disabled
                 />
          </div>
        )}
      {(formik?.values?.fund_source === 1 || formik?.values?.fund_source === 2) &&
        (formik?.values?.travel_purpose
          ? orderBasicData?.travel_purpose_list?.find(
              (item) => item?._id === formik?.values?.travel_purpose
            )?.name === "Education Overseas"
          : true) && (
          <div className="pro-mb-4">
            <FileUpload
              formik={formik}
              title={"Loan Document"}
              filePreview={imagePreview}
              handleFileChange={handleImageChange}
              handleFileRemove={handleImageRemove}
              getFieldError={getFieldError}
              isError={getFieldError("loan_document")}
            />
            {getFieldError("loan_document") && (
              <span className="error-custom">
                {getFieldError("loan_document")}
              </span>
            )}
          </div>
        )}
      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => {
            handleView();
          }}
          type="button"
        >
          Cancel
        </Button>

        <Button
          type="submit"
          className={`pro-btn-primary lg pro-ms-3 pro-px-5 ${formik.isSubmitting ? "loading" : ""}`}
          disabled={formik.isSubmitting}
          onClick={() => {
            handleNext();
          }}
        >
          Save & Next
        </Button>
      </div>
    </div>
  );
};

export default TravelDetails;
